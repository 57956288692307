import PaymentReturn from 'components/modules/e-commerce/checkout/PaymentReturn';
import OrderPaymentReturnLayout from 'layouts/OrderPaymentReturnLayout';

const OrderPaymentReturn = () => {
  return (
    <OrderPaymentReturnLayout>
      <PaymentReturn layout="simple" />
    </OrderPaymentReturnLayout>
  );
};

export default OrderPaymentReturn;
