import Avatar from 'components/base/Avatar';
import { useState } from 'react';
import { Card, Dropdown, Form, Nav } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import Scrollbar from 'components/base/Scrollbar';
import classNames from 'classnames';
import { useAuth } from 'providers/AuthProvider';

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  const { logout, userData } = useAuth();
  const navigate = useNavigate();

  const [navItems] = useState([
    {
      label: 'Perfil',
      icon: 'user',
      onClick: () => navigate('/shop/profile')
    },
  ]);

  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border'
      )}
    >
      <Card className="position-relative border-0">
      {userData && (<Card.Body className="p-0">
          
            <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
              <Avatar 
                src={userData?.profileImage} 
                size="xl" 
                className="rounded-circle"
                fallback={userData?.name} 
                userId={userData?.userId}
              />
              <h6 className="text-body-emphasis">{userData?.name}</h6>
            </div>
          
          <div style={{ height: '3rem' }}>
            <Scrollbar>
              <Nav className="nav flex-column mb-2 pb-1">
                {userData && navItems.map(item => (
                  <Nav.Item key={item.label}>
                    <Nav.Link 
                      onClick={item.onClick}
                      className="px-3"
                      style={{ cursor: 'pointer' }}
                    >
                      <FeatherIcon
                        icon={item.icon}
                        size={16}
                        className="me-2 text-body"
                      />
                      <span className="text-body-highlight">{item.label}</span>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Scrollbar>
          </div>
        </Card.Body>)}
        <Card.Footer className="p-0 border-top border-translucent">
          <hr />
          <div className="px-3">
            {userData ? (
              <button
                onClick={logout}
                className="btn btn-phoenix-danger d-flex flex-center w-100"
              >
                <FeatherIcon icon="log-out" className="me-2" size={16} />
                Sair
              </button>
            ) : (
              <div className="d-flex justify-content-between">
                <button
                  onClick={() => navigate('/login')}
                  className="btn btn-phoenix-primary d-flex flex-center w-50 me-1"
                >
                  <FeatherIcon icon="log-in" className="me-2" size={16} />
                  Entrar
                </button>
                <button
                  onClick={() => navigate('/register')}
                  className="btn btn-phoenix-secondary d-flex flex-center w-50 ms-1"
                >
                  <FeatherIcon icon="user-plus" className="me-2" size={16} />
                  Cadastro
                </button>
              </div>
            )}
          </div>
          <div className="my-2 text-center fw-bold fs-10 text-body-quaternary">
            <Link className="text-body-quaternary me-1" to="#!">
              Política de Privacidade
            </Link>
            •
            <Link className="text-body-quaternary mx-1" to="#!">
              Termos de Uso
            </Link>
          </div>
        </Card.Footer>
      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;
