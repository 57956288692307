import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import Scrollbar from 'components/base/Scrollbar';
import QuantityButtons from 'components/common/QuantityButtons';
import { CartItem } from 'helpers/cartUtils';
import { currencyFormat } from 'helpers/utils';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getCart, saveCart, increaseQuantity, decreaseQuantity, removeFromCart } from 'helpers/cartUtils';
import { Product } from 'services/api';

const EcomCartTable = () => {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);

  const updateCartItems = useCallback(() => {
    const items = getCart();
    setCartItems(items);
  }, []);

  useEffect(() => {
    updateCartItems();
  }, [updateCartItems]);

  useEffect(() => {
    const handleCartUpdated = () => {
      updateCartItems();
    };

    window.addEventListener('cartUpdated', handleCartUpdated);

    return () => {
      window.removeEventListener('cartUpdated', handleCartUpdated);
    };
  }, [updateCartItems]);

  return (
    <Scrollbar autoHeight autoHeightMax="100%" className="table-scrollbar">
      <Table className="phoenix-table fs-9 mb-0 border-top border-translucent">
        <thead>
          <tr>
            <th scope="col" />
            <th scope="col">
              PRODUTOS
            </th>
            <th className="ps-5" scope="col">
              QNTD
            </th>
            <th className="text-end" scope="col">
              TOTAL
            </th>
            <th className="text-end pe-0" scope="col" />
          </tr>
        </thead>
        <tbody className="list" id="cart-table-body">
          {cartItems.map(item => (
            <EcomCartTableRow product={item.product} quantity={item.quantity} key={item.product.productId} />
          ))}

          <tr className="cart-table-row">
            <td /> {/* TODO: Add empty row - RESOLVER */}
          </tr>
        </tbody>
      </Table>
    </Scrollbar>
  );
};

const EcomCartTableRow = ({ product, quantity }: { product: Product, quantity: number }) => {
  const [itemQuantity, setItemQuantity] = useState(quantity);

  useEffect(() => {
    setItemQuantity(quantity);
  }, [quantity]);

  const handleIncreaseQuantity = () => {
    increaseQuantity(product.productId);
    setItemQuantity(itemQuantity + 1);
  };

  const handleDecreaseQuantity = () => {
    if (itemQuantity > 1) {
      decreaseQuantity(product.productId);
      setItemQuantity(itemQuantity - 1);
    }
  };

  const handleRemoveItem = () => {
    removeFromCart(product.productId);
  };

  const total = useMemo(() => {
    const price = product.salePrice ?? product.price ?? 0;
    return price * itemQuantity;
  }, [itemQuantity, product]);

  return (
    <tr className="cart-table-row" key={product.productId}>
      <td className="py-0" style={{ width: '64px' }}>
        <div className={`border border-translucent rounded-2 ${product.special ? 'special-product' : ''}`}>
          <img src={product.image} alt={product.name} width={64} />
        </div>
      </td>
      <td>
          {product.name}
      </td>
      <td className="fs-8">
        <QuantityButtons
          type="secondary"
          quantity={itemQuantity}
          setQuantity={() => {}}
          onIncrease={handleIncreaseQuantity}
          onDecrease={handleDecreaseQuantity}
        />
      </td>
      <td className="fw-bold text-body-highlight text-end">
        {currencyFormat(total)}
      </td>
      <td className="text-end ps-0">
        <Button
          size="sm"
          variant="link"
          className="text-danger me-2"
          onClick={handleRemoveItem}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </td>
    </tr>
  );
};

export default EcomCartTable;
