import PhoenixOffcanvas from 'components/base/PhoenixOffcanvas';
import Section from 'components/base/Section';
import { useState, useEffect } from 'react';
import { Button, Col, Pagination, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Scrollbar from 'components/base/Scrollbar';
import ProductFilterItems from 'components/modules/e-commerce/products-filter/ProductFilterItems';
import ProductCard from 'components/common/ProductCard';
import ProductSpecialCard from 'components/common/ProductSpecialCard';
import {
  faChevronLeft,
  faChevronRight,
  faFilter
} from '@fortawesome/free-solid-svg-icons';
import { productService, Product } from 'services/api';

const CACHE_KEY = 'cachedProducts';
const CACHE_DURATION = 60 * 60 * 1000; // 1 hora em milissegundos

const ProductsFilter = () => {
  const [specialProducts, setSpecialProducts] = useState<Product[]>([]);
  const [Products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const debug = localStorage.getItem('debug') === 'true';
      const cachedData = localStorage.getItem(CACHE_KEY);

      if (cachedData && !debug) {
        const { products, timestamp } = JSON.parse(cachedData);
        const isCacheValid = (Date.now() - timestamp) < CACHE_DURATION;

        if (isCacheValid) {
          setSpecialProducts(products.filter((product: Product) => product.special));
          setProducts(products.filter((product: Product) => !product.special));
          return;
        }
      }

      try {
        const products: Product[] = await productService.listProducts();
        localStorage.setItem(CACHE_KEY, JSON.stringify({ products, timestamp: Date.now() }));
        setSpecialProducts(products.filter((product: Product) => product.special));
        setProducts(products.filter((product: Product) => !product.special));
      } catch (error) {
        console.error('Erro ao carregar produtos:', error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div>
      <Section className="pt-5 pb-9">
        <Row>
          <Col lg={12} xxl={12}>
            {specialProducts.length > 0 && (
              <Row className="gx-3 gy-6 mb-8 justify-content-center">
                {specialProducts.map(product => (
                  <Col xs={6} sm={6} md={4} xxl={4} key={product.productId}>
                    <div className="product-card-container h-100 d-flex justify-content-center align-items-center">
                      <ProductSpecialCard product={product} />
                    </div>
                  </Col>
                ))}
              </Row>
            )}
            {Products.length > 0 ? (
              <Row className="gx-3 gy-6 mb-8">
                {Products.map(product => (
                  <Col xs={6} sm={6} md={4} xxl={2} key={product.productId}>
                    <div className="product-card-container h-100">
                      <ProductCard product={product} />
                    </div>
                  </Col>
                ))}
              </Row>
            ) : (
              <div className="text-center">
                <p>Sem produtos disponíveis no momento.</p>
              </div>
            )}
          </Col>
        </Row>
      </Section>
    </div>
  );
};

export default ProductsFilter;
