import coroafull from 'assets/img/icons/coroafull.gif';
import { Link } from 'react-router-dom';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const PresenceConfirmationLayout = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  return (
    <div className="text-center mb-6 mx-auto">
      <img className="mb-7" src={coroafull} alt="phoenix" />
      <div className="mb-6">
        <h4 className="text-body-highlight">Presença confirmada!</h4>
        <p className="text-body-tertiary">
          Obrigado por confirmar a sua presença. <br className="d-lg-none" />
        </p>
      </div>
      <div className="d-grid">
        <Button
          className="w-100 mb-3"
          variant="primary"
          endIcon={
            <FontAwesomeIcon icon={faChevronRight} className="ms-1 fs-10" />
          }
          onClick={() => {
            window.location.href = '/shop';
          }}
        >
          Ir para a loja de presentes
        </Button>

        <div className="text-center">
          <Link
            to={`/presence`}
            className="fs-9 fw-bold"
          >
            Confirmar outra presença
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PresenceConfirmationLayout;
