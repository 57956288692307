import { useEffect, useState } from 'react';

export interface Message {
  orderId: string;
  name: string;
  value: number;
  status: string;
  special: boolean;
  cart: { name: string; quantity: number; price: number }[];
}

const wsService = (url: string) => {
  const [message, setMessage] = useState<Message | string | null>(null);
  const [messages, setMessages] = useState<(Message | string)[]>([]);

  useEffect(() => {
    const socket = new WebSocket(url);

    socket.onmessage = (event) => {
      try {
        const data: Message = JSON.parse(event.data);
        setMessage(data);
        setMessages((prevMessages) => [...prevMessages, data]);
      } catch (error) {
        setMessage(event.data);
        setMessages((prevMessages) => [...prevMessages, event.data]);
      }
    };

    return () => {
      socket.close();
    };
  }, [url]);

  return { message, messages };
};

export default wsService;