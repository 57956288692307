import Button from 'components/base/Button';
import AuthSocialButtons from 'components/common/AuthSocialButtons';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { userService } from 'services/api';

const SignUpForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    termsAccepted: false
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({
      ...formData,
      [e.target.id]: value
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (formData.password !== formData.confirmPassword) {
      toast.error('As senhas não coincidem');
      return;
    }

    if (!formData.termsAccepted) {
      toast.error('Você precisa aceitar os termos de serviço');
      return;
    }

    setIsLoading(true);
    
    try {
      const response = await userService.registerUser({
        name: formData.name,
        email: formData.email,
        password: formData.password
      });
      localStorage.setItem('token', response.token);
      localStorage.setItem('userData', JSON.stringify(response.userData));
      toast.success('Conta criada com sucesso!');
      // wait 1 second before navigating
      setTimeout(() => {
        setIsLoading(false);
        window.location.href = '/shop';
      }, 3000);
    } catch (error) {
      console.error('Erro ao criar conta:', error);
      toast.error('Erro ao criar conta. Por favor, tente novamente.');
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">Criar conta</h3>
        {/* <p className="text-body-tertiary">Create your account today</p> */}
      </div>
      {/* <AuthSocialButtons title="Sign up" />
      <div className="position-relative mt-4">
        <hr className="bg-body-secondary" />
        <div className="divider-content-center">or use email</div>
      </div> */}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="name">Nome completo</Form.Label>
          <Form.Control
            id="name"
            type="text"
            placeholder="Fulano de Tal"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">E-mail</Form.Label>
          <Form.Control
            id="email"
            type="email"
            placeholder="fulano@gmail.com"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Row className="g-3 mb-3">
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="password">Senha</Form.Label>
              <div className="position-relative">
                <Form.Control
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Crie sua senha"
                  value={formData.password}
                  onChange={handleInputChange}
                />
                <div
                  className="position-absolute top-50 end-0 translate-middle-y pe-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </div>
              </div>
            </Form.Group>
          </Col>
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="confirmPassword">Confirmar senha</Form.Label>
              <div className="position-relative">
                <Form.Control
                  id="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirme sua senha"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                />
                <div
                  className="position-absolute top-50 end-0 translate-middle-y pe-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                </div>
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Form.Check type="checkbox" className="mb-3">
          <Form.Check.Input
            type="checkbox"
            id="termsAccepted"
            checked={formData.termsAccepted}
            onChange={handleInputChange}
            required
          />
          <Form.Check.Label
            htmlFor="termsAccepted"
            className="fs-9 text-transform-none"
          >
            Li e estou de acordo com as <Link to="#!">políticas da empresa </Link>e{' '}
            <Link to="#!">políticas de privacidade</Link>
          </Form.Check.Label>
        </Form.Check>
        <Button 
          variant="primary" 
          className="w-100 mb-3" 
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? 'Criando conta...' : 'Continuar'}
        </Button>
        <div className="text-center">
          <Link
            to={`/login`}
            className="fs-9 fw-bold"
          >
            Já possui uma conta? Faça login
          </Link>
        </div>
      </Form>
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default SignUpForm;
