import { Product } from 'services/api';

export interface CartItem {
  product: Product;
  quantity: number;
}

export const getCart = (): CartItem[] => {
  return JSON.parse(localStorage.getItem('cart') || '[]');
};

export const saveCart = (cart: CartItem[]): void => {
  localStorage.setItem('cart', JSON.stringify(cart));
};

export const addToCart = (product: Product): void => {
  const cart = getCart();
  const productIndex = cart.findIndex((item) => item.product.productId === product.productId);

  if (productIndex !== -1) {
    cart[productIndex].quantity += 1;
  } else {
    cart.push({ product, quantity: 1 });
  }

  saveCart(cart);
  window.dispatchEvent(new Event('cartUpdated'));
};

export const updateCartQuantity = (productId: string, newQuantity: number): void => {
  const cart = getCart();
  const productIndex = cart.findIndex((item) => item.product.productId === productId);

  if (productIndex !== -1) {
    cart[productIndex].quantity = newQuantity;
    saveCart(cart);
    window.dispatchEvent(new Event('cartUpdated'));
  }
};

export const increaseQuantity = (productId: string): void => {
  const cart = getCart();
  const productIndex = cart.findIndex((item) => item.product.productId === productId);

  if (productIndex !== -1) {
    cart[productIndex].quantity += 1;
    saveCart(cart);
    window.dispatchEvent(new Event('cartUpdated'));
  }
};

export const decreaseQuantity = (productId: string): void => {
  const cart = getCart();
  const productIndex = cart.findIndex((item) => item.product.productId === productId);

  if (productIndex !== -1 && cart[productIndex].quantity > 1) {
    cart[productIndex].quantity -= 1;
    saveCart(cart);
    window.dispatchEvent(new Event('cartUpdated'));
  }
};

export const removeFromCart = (productId: string): void => {
  const cart = getCart();
  const updatedCart = cart.filter(item => item.product.productId !== productId);
  saveCart(updatedCart);
  window.dispatchEvent(new Event('cartUpdated'));
};

export const clearCart = (): void => {
  localStorage.removeItem('cart');
  localStorage.removeItem('isAnonymous');
  localStorage.removeItem('message');
  window.dispatchEvent(new Event('cartUpdated'));
};
