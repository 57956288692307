import Button from 'components/base/Button';
import AuthSocialButtons from 'components/common/AuthSocialButtons';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { presenceService } from 'services/api';

const PaymentReturn = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const {orderId, paymentStatus} = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    checkConfirmation: false
  });
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({
      ...formData,
      [e.target.id]: value
    });
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">Obrigado pelo seu pedido!</h3>
        Pedido #{orderId}
        {/* <p className="text-body-tertiary">Create your account today</p> */}
      </div>
      {/* <AuthSocialButtons title="Sign up" />
      <div className="position-relative mt-4">
        <hr className="bg-body-secondary" />
        <div className="divider-content-center">or use email</div>
      </div> */}
      <Button
          className="w-100 mb-3"
          variant="primary"
          endIcon={
            <FontAwesomeIcon icon={faChevronRight} className="ms-1 fs-10" />
          }
          onClick={() => {
            window.location.href = '/shop';
          }}
        >
          Voltar para a loja de presentes
        </Button>
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default PaymentReturn;
