import { faKey, faUser, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import AuthSocialButtons from 'components/common/AuthSocialButtons';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { authService } from 'services/api';
import { toast, ToastContainer } from 'react-toastify';

const SignInForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      const response = await authService.login(formData);
      if (response.token) {
        localStorage.setItem('token', response.token);
        localStorage.setItem('userData', JSON.stringify(response.userData));
        window.location.href = '/shop';
      } else {
        toast.error('Erro ao fazer login');
        console.error('Erro ao fazer login:', response);
      }
    } catch (error) {
      toast.error('Erro ao fazer login');
      console.error('Erro ao fazer login:', error);
      // Aqui você pode adicionar um toast/notificação de erro
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">Acesse sua conta</h3>
        {/*    */}
      </div>
      {/* <AuthSocialButtons title="Sign in" />
      <div className="position-relative">
        <hr className="bg-body-secondary mt-5 mb-4" />
        <div className="divider-content-center">or use email</div>
      </div> */}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">E-mail</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="email"
              type="email"
              className="form-icon-input"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <FontAwesomeIcon icon={faUser} className="text-body fs-9 form-icon" />
          </div>
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="password">Senha</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="password"
              type={showPassword ? "text" : "password"}
              className="form-icon-input"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
            <FontAwesomeIcon icon={faKey} className="text-body fs-9 form-icon" />
            <div
              className="position-absolute top-50 end-0 translate-middle-y pe-2"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowPassword(!showPassword)}
            >
              <FontAwesomeIcon 
                icon={showPassword ? faEyeSlash : faEye} 
                className="text-body fs-9"
              />
            </div>
          </div>
        </Form.Group>
        <Row className="flex-between-center mb-7">
          <Col xs="auto">
            {/* <Form.Check type="checkbox" className="mb-0">
              <Form.Check.Input
                type="checkbox"
                name="remember-me"
                id="remember-me"
                defaultChecked
              />
              <Form.Check.Label htmlFor="remember-me" className="mb-0">
                Remember me
              </Form.Check.Label>
            </Form.Check> */}
          </Col>
          <Col xs="auto">
            <Link
              to={`/forgot-password`}
              className="fs-9 fw-semibold"
            >
              Esqueceu a senha?
            </Link>
          </Col>
        </Row>
        <Button 
          variant="primary" 
          className="w-100 mb-3" 
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? 'Entrando...' : 'Entrar'}
        </Button>
      </Form>
      <div className="text-center">
        <Link
          to={`/register`}
          className="fs-9 fw-bold"
        >
          Cadastre-se
        </Link>
      </div>
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default SignInForm;
