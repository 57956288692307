import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, { RevealDropdownTrigger } from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Link } from 'react-router-dom';
import Badge, { BadgeBg } from 'components/base/Badge';
import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import { useMemo } from 'react';
import React from 'react';
import wsService, { Message } from 'services/ws';
import { Col, Row } from 'react-bootstrap';

  const projectListTableColumns: ColumnDef<Message>[] = [
    {
      accessorKey: 'name',
      header: 'Nome',
      cell: ({ row: { original } }) => {
        const { name } = original;
        return (
          <Link to="#!" className="text-decoration-none fw-bold fs-8">
            {name}
          </Link>
        );
      },
      meta: {
        cellProps: { className: 'white-space-nowrap py-4' }
      }
    },
    {
      accessorKey: 'value',
      header: 'Valor',
      cell: ({ row: { original } }) => {
        const { value } = original;
        return <span>{value.toFixed(2)}</span>;
      },
      meta: {
        cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
        headerProps: { className: 'ps-3' }
      }
    },
    {
      accessorKey: 'cart',
      header: 'Carrinho',
      cell: ({ row: { original } }) => {
        const { cart } = original;
        return (
          <span>
            {cart.map(item => (
              <>
                {item.quantity}x {item.name}
                <br />
              </>
            ))}
          </span>
        );
      },
      meta: {
        cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
        headerProps: { className: 'ps-3' }
      }
    }
  ];

const Game = () => {
  const { message, messages } = wsService('ws://ws.casamento.axfaust.com:4000');
  const [orders, setOrders] = React.useState<Message[]>([]);

  React.useEffect(() => {
    if (message && typeof message !== 'string') {
      /* const newOrder: Message = {
        orderId: message.orderId,
        name: message.name,
        status: message.status,
        value: message.value,
        cart: message.cart.map((item: any) => ({
          productId: item.productId,
          quantity: item.quantity,
          price: item.price,
          name: item.name
        })),
        special: message.special
      }; */
      setOrders((prevOrders) => [...prevOrders, message]);
    }
  }, [message]);

  return (
    <Row>
      <Col md={6}>
        <TableLastOrders orders={orders} />
      </Col>
      <Col md={6}>
        <div className="pt-5 mb-9">
          {message && (
            typeof message === 'string' ? (
              <p>{message}</p>
            ) : (
              <div>
                <h3>{message.name}</h3>
                <p>Valor: {message.value}</p>
                <p>Especial: {message.special ? 'Sim' : 'Não'}</p>
                <h4>Carrinho:</h4>
                <ul>
                  {message.cart.map((item, index) => (
                    <li key={index}>
                      {item.name} - Quantidade: {item.quantity} - Preço: {item.price}
                      <br />
                    </li>
                  ))}
                </ul>
              </div>
            )
          )}

          <h4>Histórico de Mensagens:</h4>
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Valor</th>
                <th>Especial</th>
                <th>Carrinho</th>
              </tr>
            </thead>
            <tbody>
              {messages.map((msg, index) => (
                typeof msg === 'string' ? (
                  <tr key={index}>
                    <td colSpan={4}>{msg}</td>
                  </tr>
                ) : (
                  <tr key={index}>
                    <td>{msg.name}</td>
                    <td>{msg.value}</td>
                    <td>{msg.special ? 'Sim' : 'Não'}</td>
                    <td>
                      <ul>
                        {msg.cart.map((item, idx) => (
                          <li key={idx}>
                            {item.name} - Quantidade: {item.quantity} - Preço: {item.price}
                            <br />
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                )
              ))}
            </tbody>
          </table>
        </div>
      </Col>
    </Row>
  );
};

const TableLastOrders = ({ orders }: { orders: Message[] }) => {
  const table = useAdvanceTable({
    data: orders,
    columns: projectListTableColumns,
    pageSize: 6,
    pagination: true,
    selection: true,
    sortable: true
  });

  const { getColumn, getPrePaginationRowModel } = table;

  const handleFilterItemClick = (columnId: string, value: string) => {
    const column = getColumn(columnId);
    column?.setFilterValue(value === 'all' ? '' : value);
  };

  const tabItems: FilterTabItem[] = useMemo(() => {
    const getDataCount = (label: string) =>
      getPrePaginationRowModel().rows.filter(
        ({ original: { status } }: any) => status.label === label
      ).length;

    return [
      {
        label: 'All',
        value: 'all',
        onClick: () => handleFilterItemClick('status', 'all'),
        count: getPrePaginationRowModel().rows.length
      },
      {
        label: 'Ongoing',
        value: 'ongoing',
        onClick: () => handleFilterItemClick('status', 'ongoing'),
        count: getDataCount('ongoing')
      },
      {
        label: 'Cancelled',
        value: 'cancelled',
        onClick: () => handleFilterItemClick('status', 'cancelled'),
        count: getDataCount('cancelled')
      },
      {
        label: 'Completed',
        value: 'completed',
        onClick: () => handleFilterItemClick('status', 'completed'),
        count: getDataCount('completed')
      },
      {
        label: 'Critical',
        value: 'critical',
        onClick: () => handleFilterItemClick('status', 'critical'),
        count: getDataCount('critical')
      }
    ];
  }, [getPrePaginationRowModel]);

  return (
    <AdvanceTableProvider {...table}>
      <FilterTab tabItems={tabItems} className="mb-3" />
      <AdvanceTable
        tableProps={{
          size: 'sm',
          className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
        }}
        rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
      />
      <AdvanceTableFooter navBtn />
    </AdvanceTableProvider>
  );
};


export default Game;
