import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

/* eslint-disable react/prop-types */
interface QuantityButtonsProps {
  type: string;
  quantity: number;
  setQuantity: (quantity: number) => void;
  onIncrease: () => void;
  onDecrease: () => void;
}

const QuantityButtons: React.FC<QuantityButtonsProps> = ({
  type,
  quantity,
  setQuantity,
  onIncrease,
  onDecrease,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuantity(Number(e.target.value));
  };

  if (type === 'default') {
    return (
      <div className="d-flex flex-between-center">
        <Button
          variant="phoenix-primary"
          className="px-3"
          onClick={onDecrease}
        >
          <FontAwesomeIcon icon={faMinus} />
        </Button>
        <input
          type="number"
          className="form-control text-center input-spin-none bg-transparent border-0 outline-none"
          style={{ width: 50 }}
          min="1"
          value={quantity}
          onChange={handleChange}
        />
        <Button
          variant="phoenix-primary"
          className="px-3"
          onClick={onIncrease}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </div>
    );
  }
  return (
    <div className="input-group input-group-sm flex-nowrap">
      <Button size="sm" className="px-2" onClick={onDecrease}>
        -
      </Button>
      <input
        className="form-control text-center input-spin-none bg-transparent border-0 px-0"
        type="number"
        min={1}
        value={quantity}
        onChange={handleChange}
      />
      <Button size="sm" className="px-2" onClick={onIncrease}>
        +
      </Button>
    </div>
  );
};

export default QuantityButtons;
