import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import Badge, {BadgeBg} from 'components/base/Badge';
import { CustomerOrder, customerOrders } from 'data/e-commerce';
import { currencyFormat } from 'helpers/utils';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { useState } from 'react';
import { orderService } from 'services/api';
import { useEffect } from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const columns: ColumnDef<any>[] = [
  {
    accessorKey: 'orderId',
    header: 'Pedido',
    cell: ({ row: { original } }) => (
      <Link to="#!" className="fw-semibold text-primary">
        {original.orderId}
      </Link>
    ),
    meta: {
      headerProps: { style: { width: '15%', minWidth: 140 } },
      cellProps: { className: 'py-2' }
    }
  },
  {
    accessorKey: 'cartValue',
    header: 'Valor do Pedido',
    cell: ({ row: { original } }) => currencyFormat(original.cartValue),
    meta: {
      headerProps: { style: { width: '15%', minWidth: 160 } },
      cellProps: { className: 'fw-semibold text-end text-body-highlight' }
    }
  },
  {
    accessorKey: 'paymentStatus',
    header: 'Estado do Pagamento',
    cell: ({ row: { original } }) => {
      const statusTranslation: Record<string, { status: string, class: BadgeBg }> = {
        pending: { status: 'Pendente', class: 'warning' },
        approved: { status: 'Aprovado', class: 'success' },
        inprocess: { status: 'Em Processo', class: 'info' },
        inmediation: { status: 'Em Mediação', class: 'secondary' },
        rejected: { status: 'Rejeitado', class: 'danger' },
        cancelled: { status: 'Cancelado', class: 'info' },
        refunded: { status: 'Reembolsado', class: 'primary' },
        chargedback: { status: 'Chargeback', class: 'danger' }
      };

      const translatedStatus = statusTranslation[original.paymentStatus] || { status: original.paymentStatus, class: 'default' };

      return (
        <Badge
          bg={translatedStatus.class}
          variant="phoenix"
          className="fs-10"
        >
          {translatedStatus.status}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: '15%', minWidth: 180 } }
    }
  },
  {
    accessorKey: 'createdAt',
    header: 'Data',
    cell: ({ row: { original } }) => (
      format(new Date(original.createdAt.replace(' ', 'T')), 'dd/MM/yyyy HH:mm')
    ),
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: 160 },
        className: 'text-end'
      },
      cellProps: { className: 'text-body-tertiary text-end' }
    }
  },/* 
  {
    id: 'action',
    cell: () => (
      <RevealDropdownTrigger>
        <RevealDropdown>
          <ActionDropdownItems />
        </RevealDropdown>
      </RevealDropdownTrigger>
    ),
    meta: {
      headerProps: { style: { width: '15%' }, className: 'text-end' },
      cellProps: { className: 'text-end py-2' }
    }
  } */
];

const EcomProfileOrdersTable = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const data = await orderService.listOrders();
        setOrders(data);
      } catch (error) {
        console.error('Erro ao buscar pedidos:', error);
      }
    };

    fetchOrders();
  }, []);
  
  const table = useAdvanceTable({
    data: orders,
    columns,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="border-y border-translucent">
          <AdvanceTable
            tableProps={{ size: 'sm', className: 'phoenix-table fs-9' }}
          />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default EcomProfileOrdersTable;
