import Button from 'components/base/Button';
import AuthSocialButtons from 'components/common/AuthSocialButtons';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { presenceService } from 'services/api';
import ReCAPTCHA from 'react-google-recaptcha';

const SignUpForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    checkConfirmation: false
  });
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({
      ...formData,
      [e.target.id]: value
    });
  };

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!recaptchaToken) {
      toast.error('Por favor, complete o reCAPTCHA');
      return;
    }

    if (!formData.checkConfirmation) {
      toast.error('Você precisa confirmar a sua presença');
      return;
    }

    setIsLoading(true);
    
    try {
      const response = await presenceService.confirmPresence({
        name: formData.name,
        email: formData.email,
        message: formData.message,
        recaptchaToken: recaptchaToken
      });
      toast.success('Presença confirmada com sucesso!');
      // wait 1 second before navigating
      setTimeout(() => {
        setIsLoading(false);
        window.location.href = '/presence/success';
      }, 3000);
    } catch (error) {
      console.error('Erro ao confirmar presença:', error);
      toast.error('Erro ao confirmar presença. Por favor, tente novamente.');
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">Confirmar presença</h3>
        {/* <p className="text-body-tertiary">Create your account today</p> */}
      </div>
      {/* <AuthSocialButtons title="Sign up" />
      <div className="position-relative mt-4">
        <hr className="bg-body-secondary" />
        <div className="divider-content-center">or use email</div>
      </div> */}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="name">Nome completo</Form.Label>
          <Form.Control
            id="name"
            type="text"
            placeholder="Fulano de Tal"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">E-mail</Form.Label>
          <Form.Control
            id="email"
            type="email"
            placeholder="fulano@gmail.com"
            value={formData.email}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Recado</Form.Label>
          <Form.Control 
            as="textarea" 
            rows={3}
            value={formData.message}
            onChange={(e) => setFormData(prev => ({ ...prev, message: e.target.value }))}
          />
        </Form.Group>
        <Form.Check type="checkbox" className="mb-5">
          <Form.Check.Input
            type="checkbox"
            id="checkConfirmation"
            checked={formData.checkConfirmation}
            onChange={handleInputChange}
            required
          />
          <Form.Check.Label
            htmlFor="checkConfirmation"
            className="fs-9 text-transform-none"
          >
            Confirmo que estarei presente no casamento
          </Form.Check.Label>
        </Form.Check>
        <Form.Group className="mb-3">
          <ReCAPTCHA theme={localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'}
            sitekey={process.env.RECAPTCHA_PUBLIC || '6LeK0bUqAAAAAPpmi2rtsx43x9bXXVGhguBaHcDQ'}
            onChange={handleRecaptchaChange}
          />
        </Form.Group>
        <Button 
          variant="primary" 
          className="w-100 mb-3" 
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? 'Confirmando a sua presença...' : 'Continuar'}
        </Button>
      </Form>
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default SignUpForm;
