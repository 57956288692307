import PresenceForm from 'components/modules/e-commerce/forms/PresenceForm';
import PresenceLayout from 'layouts/PresenceLayout';

const Presence = () => {
  return (
    <PresenceLayout>
      <PresenceForm layout="simple" />
    </PresenceLayout>
  );
};

export default Presence;
