import { faAdd, faClock, faHeart, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Badge, { BadgeBg } from 'components/base/Badge';
import Button from 'components/base/Button';
import Rating from 'components/base/Rating';
import { currencyFormat } from 'helpers/utils';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addToCart } from 'helpers/cartUtils';
import { Product } from 'services/api';

const ProductSpecialCard = ({ product }: { product: Product }) => {
  const handleAddToCart = () => {
    addToCart(product);
    toast.success('Produto adicionado ao carrinho com sucesso!');
  };

  return (
    <>
      <ToastContainer position="bottom-right" />
      <div className="position-relative text-decoration-none product-card h-100">
        <div className="d-flex flex-column justify-content-between h-100">
          <div>
            <div className="border border-translucent rounded-3 position-relative mb-3 special-product">
              {/* <Button
                variant={product.wishListed ? 'primary' : 'outline-primary'}
                className="rounded-circle p-0 d-flex flex-center btn-wish z-2 d-toggle-container"
              >
                {product.wishListed ? (
                  <FontAwesomeIcon icon={faHeart} />
                ) : (
                  <>
                    <FontAwesomeIcon icon={faHeart} className="d-block-hover" />
                    <FontAwesomeIcon icon={farHeart} className="d-none-hover" />
                  </>
                )}
              </Button> */}
              <img src={product.image} alt="" className="img-fluid" />
              {product.badge && (
                <Badge bg={product.badgeClass as BadgeBg || 'primary'} className="fs-10 product-verified-badge">
                  {product.badgeText}
                  <FontAwesomeIcon icon={faClock} className="ms-1" />
                </Badge>
              )}
            </div>
              <h6 className="mb-2 lh-sm line-clamp-3 product-name">
                {product.name}
              </h6>
            {/* {product.rating && (
              <p className="fs-9">
                <Rating readonly initialValue={product.rating} />
                {product.rated && (
                  <span className="text-body-quaternary fw-semibold ms-1">
                    ({product.rated} people rated)
                  </span>
                )}
              </p>
            )} */}
          </div>
          <div>
            {product.extra && (
              <p className={classNames(product.extraClass, 'fs-9')}>
                {product.extra}
              </p>
            )}
            {product.extra2 && (
              <p className={classNames(product.extraClass2, 'fs-9')}>
                {product.extra2}
              </p>
            )}

            {product.salePrice && (
              <>
                {product.price ? (
                  <div className="d-flex align-items-center mb-1">
                    <p className="me-2 text-body text-decoration-line-through mb-0">
                      {currencyFormat(product.price)}
                    </p>
                    <h3 className="text-body-emphasis mb-0">
                      {currencyFormat(product.salePrice)}
                    </h3>
                  </div>
                ) : (
                  <h3 className="text-body-emphasis">
                    {currencyFormat(product.salePrice)}
                  </h3>
                )}
              </>
            )}

            {/* {product.colors && (
              <p
                className={classNames(
                  'text-body-tertiary fw-semibold fs-9 lh-1',
                  {
                    'mb-0': !product.dealEndTime
                  }
                )}
              >
                {product.colors} colors
              </p>
            )} */}

            {/* {product.dealEndTime && (
              <p className="text-success fw-bold fs-9 lh-1 mb-0">
                Deal time ends in {product.dealEndTime}
              </p>
            )} */}

            {product.offer && (
              <h6 className="text-success lh-1 mb-0">{product.offer} off</h6>
            )}

            <div className="d-flex align-items-center justify-content-end mb-2">
              <Button variant="success" onClick={handleAddToCart}>
                <FontAwesomeIcon icon={faAdd} />
              </Button>
            </div>

            
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSpecialCard;