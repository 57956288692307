import PresenceConfirmationLayout from 'layouts/PresenceLayout';
import PresenceConfirmationForm from 'components/modules/e-commerce/forms/PresenceConfirmationForm';

const PresenceConfirmation = () => {
  return (
    <PresenceConfirmationLayout logo={false}>
      <PresenceConfirmationForm layout="simple" />
    </PresenceConfirmationLayout>
  );
};

export default PresenceConfirmation;
