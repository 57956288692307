import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import OrderSummaryDetails from 'components/common/OrderSummaryDetails';
import { currencyFormat } from 'helpers/utils';
import React, { useEffect, useState, useCallback } from 'react';
import { Card, Form, FormControl, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getCart, CartItem, clearCart } from 'helpers/cartUtils';
import { orderService } from 'services/api';
import { toast, ToastContainer } from 'react-toastify';
import InputMask from 'react-input-mask';
import { useAuth } from 'providers/AuthProvider';
import { useNavigate } from 'react-router-dom';

const EcomCartSummaryCard = () => {
  const { userData } = useAuth();
  const [totalAmount, setTotalAmount] = useState(0);
  const [isAnonymous, setIsAnonymous] = useState(() => {
    const savedIsAnonymous = localStorage.getItem('isAnonymous');
    return savedIsAnonymous ? JSON.parse(savedIsAnonymous) : false;
  });
  const [formData, setFormData] = useState(() => {
    const savedMessage = localStorage.getItem('message');
    return {
      message: savedMessage || ''
    };
  });
  const navigate = useNavigate();

  const calculateTotalAmount = useCallback(() => {
    const cartItems: CartItem[] = getCart();
    const total = cartItems.reduce((sum, item) => {
      const price = item.product.salePrice ?? item.product.price ?? 0;
      return sum + price * item.quantity;
    }, 0);
    setTotalAmount(total);
  }, []);

  useEffect(() => {
    calculateTotalAmount();

    const handleCartUpdated = () => {
      calculateTotalAmount();
    };

    window.addEventListener('cartUpdated', handleCartUpdated);

    return () => {
      window.removeEventListener('cartUpdated', handleCartUpdated);
    };
  }, [calculateTotalAmount]);

  useEffect(() => {
    localStorage.setItem('isAnonymous', JSON.stringify(isAnonymous));
  }, [isAnonymous]);

  useEffect(() => {
    localStorage.setItem('message', formData.message);
  }, [formData.message]);

  const handlePaymentClick = async () => {
    try {
      const isUserLoggedIn = !!userData && !!userData.userId;

      if (!isUserLoggedIn) {
        const currentUrl = window.location.href;
        localStorage.setItem('redirectAfterLogin', currentUrl);

        navigate('/login');
        return;
      }

      const cartItems = getCart();
      const cart = cartItems.map(item => ({ productId: item.product.productId, quantity: item.quantity }));

      const response = await orderService.createOrder({
        ...formData,
        isAnonymous,
        cart
      });
      if (response.init_point) {
        // Limpar o carrinho
        clearCart();
        window.location.href = response.init_point;
      }
    } catch (error) {
      toast.error('Erro ao finalizar o pedido');
      console.error('Erro ao criar preferência de pagamento:', error);
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <div className="d-flex flex-between-center mb-3">
            <h3 className="mb-0">Resumo</h3>
          </div>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Deixe uma mensagem</Form.Label>
            <Form.Control 
              as="textarea" 
              rows={3}
              value={formData.message}
              onChange={(e) => setFormData(prev => ({ ...prev, message: e.target.value }))}
            />
          </Form.Group>

          <Form.Check
            type="checkbox"
            label="Enviar presente anonimamente"
            className="mb-3"
            checked={isAnonymous}
            onChange={(e) => setIsAnonymous(e.target.checked)}
          />

          <div className="d-flex justify-content-between border-y border-dashed border-translucent py-3 mb-4">
            <h4 className="mb-0">Total :</h4>
            <h4 className="mb-0">
              {currencyFormat(totalAmount, { minimumFractionDigits: 2 })}
            </h4>
          </div>
          <Button
            className="w-100 mb-1"
            variant="primary"
            endIcon={
              <FontAwesomeIcon icon={faChevronRight} className="ms-1 fs-10" />
            }
            disabled={totalAmount === 0}
            onClick={handlePaymentClick}
          >
            Ir para o pagamento
          </Button>
        </Card.Body>
      </Card>
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default EcomCartSummaryCard;
